$( document ).on('turbolinks:load', function() {
  $('body').on('click', '.print-action', function(e){
    var print_url = $(this).attr('data-print-url');
    var winPrint = window.open(print_url, '', 'left=0,top=0,toolbar=0,scrollbars=0,status=0');
    winPrint.document.close();
    winPrint.focus();
    winPrint.print();
    e.preventDefault();
  })
  
  $('#search-customers #from').datetimepicker({
              timepicker:true,
              closeOnDateSelect:false,
              closeOnTimeSelect: true,
              initTime: true,
              format: 'm-d-Y H:i',
              roundTime: 'ceil'
            });
  $('#search-customers #to').datetimepicker({
              timepicker:true,
              closeOnDateSelect:false,
              closeOnTimeSelect: true,
              initTime: true,
              format: 'm-d-Y H:i',
              roundTime: 'ceil',});
});
